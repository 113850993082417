import React, { createContext, useState, useContext } from 'react';
import moment from 'moment';
import { DEFAULT_VIEW, DEFAULT_TAB, DEFAULT_TITLE, DEFAULT_START_DAY_COUNT, DEFAULT_MAX_DAY_COUNT } from '../../../../constants/ConstantsWI'

const WirelesIinsightStateContext = createContext();
export const useWirelesIinsightStateContext = () => {
    return useContext(WirelesIinsightStateContext);
};

export const WirelesIinsightProvider = ({ children }) => {

    const [currentWiView, setCurrentWiView] = useState(sessionStorage.getItem("currentWiView") || DEFAULT_VIEW)  // 'WiDashboard', 'GroupDashboard', 'DeviceDashboard'
    const [currentTitle, setCurrentTitle] = useState(sessionStorage.getItem("currentTitle") || DEFAULT_TITLE) // 'Wireless Insights', currentGroup, currentDevice
    const [currentTab, setCurrentTab] = useState(sessionStorage.getItem("currentTab") || DEFAULT_TAB) // 'Dashboard', 'Wi-Fi Insights', 'Cellular Insights'
    const [currentGroupId, setCurrentGroupId] = useState(parseInt(sessionStorage.getItem("currentGroupId")))
    const [currentGroupName, setCurrentGroupName] = useState(sessionStorage.getItem("currentGroupName"))
    const [currentDeviceId, setCurrentDeviceId] = useState(parseInt(sessionStorage.getItem("currentDeviceId")))
    const [currentDeviceName, setCurrentDeviceName] = useState(sessionStorage.getItem("currentDeviceName"))
    const [notificationCount, setNotificationCount] = useState(0)
    const defaultStartDateTmp = moment().subtract(DEFAULT_START_DAY_COUNT, 'days');
    const [defaultStartDate ] = useState(defaultStartDateTmp.subtract(1, 'minute'))
    const [defaultEndDate ] = useState(moment());
    const maxPrevTime = moment().subtract(DEFAULT_MAX_DAY_COUNT, 'days');
    const [endTime, setEndTime] = useState(defaultEndDate)
    const [startTime, setStartTime] = useState(defaultStartDate)
    const [wiToken, setWiToken] = useState(null)
    const [wiCred, setWiCred] = useState(null)
    // const [endTime, setEndTime] = useState(moment(sessionStorage.getItem("currentEndTime")) || defaultEndDate)
    // const [startTime, setStartTime] = useState(moment(sessionStorage.getItem("currentStartTime")) || defaultStartDate)
    const [breadCrumbList, setBreadCrumbList] = useState(JSON.parse(sessionStorage.getItem("currentBD")) || [])
    const [wiEventCode, setWiEventCode] = useState(null)
    const [wiEventName, setWiEventName] = useState(null)

    // const [currentWiView, setCurrentWiView] = useState(DEFAULT_VIEW)  // 'WiDashboard', 'GroupDashboard', 'DeviceDashboard'
    // const [currentTitle, setCurrentTitle] = useState(DEFAULT_TITLE) // 'Wireless Insights', currentGroup, currentDevice
    // const [currentTab, setCurrentTab] = useState(DEFAULT_TAB) // 'Dashboard', 'Wi-Fi Insights', 'Cellular Insights'
    // const [currentGroupId, setCurrentGroupId] = useState(null)
    // const [currentGroupName, setCurrentGroupName] = useState(null)
    // const [currentDeviceId, setCurrentDeviceId] = useState(null)
    // const [currentDeviceName, setCurrentDeviceName] = useState(null)
    // const [notificationCount, setNotificationCount] = useState(0)
    // const defaultStartDateTmp = moment().subtract(DEFAULT_START_DAY_COUNT, 'days');
    // const [defaultStartDate, setDefaultStartDate ]= useState(defaultStartDateTmp.subtract(1, 'minute'))
    // const [defaultEndDate, setDefaultEndDate] = useState(moment());
    // const maxPrevTime = moment().subtract(DEFAULT_MAX_DAY_COUNT, 'days');
    // const [endTime, setEndTime] = useState( defaultEndDate)
    // const [startTime, setStartTime] = useState( defaultStartDate)
    // const [breadCrumbList, setBreadCrumbList] = useState([])

    if (sessionStorage.getItem("currentStartTime") === null) {
        sessionStorage.setItem("currentStartTime", defaultStartDate.toISOString())
    }
    if (sessionStorage.getItem("currentEndTime") === null) {
        sessionStorage.setItem("currentEndTime", defaultEndDate.toISOString())
    }

    if (sessionStorage.getItem("currentWiView") === null) {
        sessionStorage.setItem("currentWiView", DEFAULT_VIEW)
    }
    if (sessionStorage.getItem("currentTitle") === null) {
        sessionStorage.setItem("currentTitle", DEFAULT_TITLE)
    }
    if (sessionStorage.getItem("currentTab") === null) {
        sessionStorage.setItem("currentTab", DEFAULT_TAB)
    }
    if (sessionStorage.getItem("currentGroupId") === null) {
        sessionStorage.setItem("currentGroupId", null)
    }

    if (sessionStorage.getItem("currentGroupName") === null) {
        sessionStorage.setItem("currentGroupName", null)
    }
    if (sessionStorage.getItem("currentDeviceId") === null) {
        sessionStorage.setItem("currentDeviceId", null)
    }

    if (sessionStorage.getItem("currentDeviceName") === null) {
        sessionStorage.setItem("currentDeviceName", null)
    }

    if (sessionStorage.getItem("currentBD") === null) {
        sessionStorage.setItem("currentBD", JSON.stringify([]))
    }

    const value = {
        currentWiView,
        setCurrentWiView,
        currentTitle,
        setCurrentTitle,
        currentTab,
        setCurrentTab,
        currentGroupId,
        setCurrentGroupId,
        currentGroupName,
        setCurrentGroupName,
        currentDeviceId,
        setCurrentDeviceId,
        currentDeviceName,
        setCurrentDeviceName,
        notificationCount,
        setNotificationCount,
        endTime,
        setEndTime,
        startTime,
        setStartTime,
        defaultEndDate,
        defaultStartDate,
        maxPrevTime,
        setBreadCrumbList,
        breadCrumbList,
        wiToken,
        setWiToken,
        wiCred, 
        setWiCred,
        wiEventCode, 
        setWiEventCode,
        wiEventName, 
        setWiEventName
    }

    return (
        <WirelesIinsightStateContext.Provider value={value}>
            {children}
        </WirelesIinsightStateContext.Provider>
    );
};



