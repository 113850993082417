export const SUCCESS_STATUS_CODE = Object.freeze([200, 201])
export const DEFAULT_VIEW = "WiDashboard"
export const DEFAULT_TAB = "Dashboard"
export const DEFAULT_TITLE = "Wireless Insights"
export const WI_TITLE_NO_WC = Object.freeze(['Dashboard', 'Advance Search', 'Settings'])
export const DEFAULT_TIME_DURATION = 60
export const DEFAULT_MAX_TIME_DURATION = 60
export const DISABLE_WIFI_INSIGHTS = false
export const DISABLE_CELLULAR_INSIGHTS = false
export const DEFAULT_START_DAY_COUNT = 1
export const DEFAULT_MAX_DAY_COUNT = 30
export const DEFAULT_LABEL_POINTS = 30
export const DEFAULT_SKIP = 0
export const DEFAULT_LIMIT = 25
export const DEFAULT_PAGE_OPTIONS = Object.freeze([25, 50, 75, 100])
export const WIFI_SIGNAL_STRENGTH_FAILURE_YTICK_VALUES = Object.freeze([1, 2, 3, 4, 5, 6]);
export const WIFI_SIGNAL_STRENGTH_FAILURE_YTICK_LABELS = Object.freeze(['Poor', 'Fair', 'Good', 'Very Good', 'Excellent', '']);
export const WIFI_SIGNAL_STRENGTH_FAILURE_Y1TICK_LABELS = Object.freeze(["", 'Poor', 'Fair', 'Good', 'Very Good', 'Excellent', '']);
export const WIFI_SIGNAL_STRENGTH_FAILURE_Y2TICK_LABELS = Object.freeze(["", '20%', '40%', '60%', '80%', '100%', '']);
export const CELLULAR_SIGNAL_STRENGTH_FAILURE_YTICK_VALUES = Object.freeze([1, 2, 3, 4, 5, 6]);
export const CELLULAR_SIGNAL_STRENGTH_FAILURE_YTICK_LABELS = Object.freeze(["", 'Poor', 'Fair', 'Good', 'Very Good', 'Excellent', ""]);
export const WIFI_CONNECTION_LABELS = Object.freeze(["Excellent", "Very Good", "Good", "Fair", "Poor"]);
export const WIFI_CONNECTION_COLORS = Object.freeze({
    "Excellent": '#67B796',
    "Very Good": '#00864F',
    "Good": '#005F38',
    "Fair": '#DBB91C',
    "Poor": '#D70015'
});
export const WIFI_SIGNAL_STRENGTH_COLORS = Object.freeze({
    "Excellent": '#67B796',
    "Very Good": '#00864F',
    "Good": '#005F38',
    "Fair": '#DBB91C',
    "Poor": '#D70015'
});
export const WIFI_EVENT_LABELS = Object.freeze(["Wi-Fi Disconnection", "Wi-Fi Voice", "Wi-Fi Roam", "Wi-Fi Connection"]);
export const WIFI_EVENT_COLORS = Object.freeze({
    "Wi-Fi Disconnection": '#1290FF',
    "Wi-Fi Voice": "#2DB896",
    "Wi-Fi Roam": '#0000D1',
    "Wi-Fi Connection": "#D229DF"
});
export const WIFI_EVENT_ENUM = Object.freeze({
    "Wi-Fi Disconnection": 'DISCONNECT',
    "Wi-Fi Voice": 'VOICE',
    "Wi-Fi Roam": 'ROAM',
    "Wi-Fi Connection": 'CONNECTION'
});
export const WIFI_SIGNAL_STRENGTH_FAILURE_COLORS = Object.freeze({
    "strength": "#0061C2",
    "failure": "#D70015"
})
export const WIFI_SIGNAL_STRENGTH_FAILURE_LABELS = Object.freeze({
    "strength": "Signal Strength",
    "failure": "Failures"
})
// export const CELLULAR_SS_COLORS = Object.freeze(["#3EBDEB", "#C30121", "#FFBC30", "#1ABC9C", "#2ECC71", "#F1C40F", "#D35400", "#34495E"])
export const CELLULAR_SS_COLORS = Object.freeze(["#3EBDEB", "#C30121", "#FFBC30", "#1ABC9C", "#FF5733", "#33FF57", "#3357FF", "#FFFF33", "#FF33A1", "#33FFF1", "#FF8C33", "#33FF8C", "#A133FF", "#FF33D4"])
export const CELLULAR_SIGNAL_STRENGTH_FAILURE_COLORS = Object.freeze({
    "strength": "#00fb7a",
    "failure": "#F65058FF"
})
export const CELLULAR_SIGNAL_STRENGTH_FAILURE_LABELS = Object.freeze({
    "T-Mobile": "Signal Strength",
    "failure": "Failures"
})

export const CELLULAR_EVENT_COLORS = Object.freeze({
    "Out of Service Failures": '#0D8B9F',
    "Acquisition Failures": '#3A71F8',
    "Voice Call Failures": '#FE7C21',
})

export const CELLULAR_GROUP_EVENT_COLORS = Object.freeze({
    "Out of Service Failures": '#0D8B9F',
    "Acquisition Failures": '#3A71F8',
    "Voice Call Failures": '#FE7C21',
    "Poor Data Throughput": '#F65058FF',
})

export const CELLULAR_GROUP_FAILURE_EVENT_COLORS = Object.freeze({
    "Acquisition": "#1578D4",
    "Handover": "#43008F",
    "Out of Service": "#00DDDD",
    "Voice Call": "#FF7F0E",
})
export const CELLULAR_DEVICE_ALL_FAILURE_EVENT_COLORS = Object.freeze({
    "Acquisition": "#1578D4",
    "Data Disconnection": "#E377C2",
    "Handover": "#43008F",
    "Out of Service": "#00DDDD",
    "Voice Call": "#FF7F0E",
})
export const CELLULAR_EVENT_ENUM = Object.freeze({
    "Out of Service Failures": "OUT_OF_SERVICE",
    "Acquisition Failures": "ACQUISITION",
    "Voice Call Failures": "VOICE_CALL",
})

export const CELLULAR_GROUP_EVENT_ENUM = Object.freeze({
    "Acquisition": "ACQUISITION",
    "Handover": "HANDOVER",
    "Out of Service": "OUT_OF_SERVICE",
    "Voice Call": "VOICE_CALL",
})

export const CELLULAR_EVENT_CODE_MAP_HEAD = Object.freeze({
    "subscriptionInfo": "Subscription Info",
    "cellInfo": "Cell Info",
    "signalStrength": "Signal Strength",
    "serviceState": "Out of Service",
    "cellErrorInfo": "Acquisition",
    "imsStatusInfo": "IMS Status Info",
    "callInfo": "Call Info",
    "callErrorInfo": "Voice Call",
    "locationInfo": "Location",
    "hoInfo": "Handover",
    "cellErrorRateExt": "Poor Data Throughput",
    "ipErrorInfo": "Data Disconnection",
    "servingCellInfo": "Serving Cell Info",
    "neighborCellInfo": "Neighbor Cell Info",
    "servingCellSS": "Serving Cell Signal Strength",
    "neighborCellSS": "Neighbor Cell Signal Strength",
    "poorCoverage": "Poor Coverage",
    "cellInterference": "Cell Interference"
})

export const CELLULAR_VOICE_EVENT_CODE_MAP_HEAD = Object.freeze({
    "SIP": "PS Call",
    "CS": "CS Call"
})


export const CELLULAR_EVENT_CODE_MAP = Object.freeze({
    "subscriptionInfo": "Subscription Info",
    "cellInfo": "Cell Info",
    "signalStrength": "Signal Strength",
    "serviceState": "Service State",
    "cellErrorInfo": "Acquisition",
    "imsStatusInfo": "IMS Status Info",
    "callInfo": "Call Info",
    "callErrorInfo": "Voice Call",
    "locationInfo": "Location",
    "hoInfo": "Handover",
    "cellErrorRateExt": "Poor Data Throughput",
    "ipErrorInfo": "Data Disconnection",
    "servingCellInfo": "Serving Cell Info",
    "neighborCellInfo": "Neighbor Cell Info",
    "servingCellSS": "Serving Cell Signal Strength",
    "neighborCellSS": "Neighbor Cell Signal Strength"
})

export const SIGNAL_STRENGTH_CARRIER_COLORS = Object.freeze({
    "Excellent": '#67B796',
    "Very Good": '#00864F',
    "Good": '#005F38',
    "Fair": '#DBB91C',
    "Poor": '#D70015'
})
export const STATUS_BACKGROUND_COLORS = Object.freeze({
    "Excellent": '#67B79633',
    "Very Good": '#00864F33',
    "Good": '#005F3833',
    "Fair": '#DBB91C33',
    "Poor": '#D7001533'
})
export const STATUS_BORDER_COLORS = Object.freeze({
    "Excellent": '1px solid #67B796',
    "Very Good": '1px solid #00864F',
    "Good": '1px solid #005F38',
    "Fair": '1px solid #DBB91C',
    "Poor": '1px solid #D70015'
})
export const STATUS_CODE_MAP = Object.freeze({
    5: "Excellent",
    4: "Very Good",
    3: "Good",
    2: "Fair",
    1: "Poor"
})
export const CODE_STATUS_MAP = Object.freeze({
    "Excellent": 5,
    "Very Good": 4,
    "Good": 3,
    "Fair": 2,
    "Poor": 1
})
export const TOTAL_FAILURES_COLORS = Object.freeze({
    "DEVICE": "#9415B8",
    "RF": '#07149E',
    "AP Interop": '#59B02B',
    "Network": "#CCAB00",

})
export const DEVICE_TIME_SPENT_ON_BAND_COLOR = Object.freeze({
    "2.4": "#232BAF",
    "5G": '#1B67D2',
    "6Ghz": "#16BEDB"
})
export const GROUP_TOTAL_EVENTS_WIFI_COLOR = Object.freeze(["#00864F", "#D70015"])
export const GROUP_TOTAL_EVENTS_WIFI_CATEGORIES = Object.freeze(["Voice", "Roam", "Connection"])
export const GROUP_TOTAL_EVENTS_WIFI_CATEGORIES_MAP = Object.freeze({
    "Voice": "VOICE",
    "Roam": "ROAM",
    "Connection": "CONNECTION",
    "Disconnect": "DISCONNECT"
})
export const ALL_WIFI_EVENT_LABELS = Object.freeze({
    664: "Connection Started",
    610: "Connection Completed",
    611: "Connection Failed",
    612: "Roam Started",
    614: "Roam Completed",
    615: "Roam Failed",
    632: "Disconnection",
    641: "Disconnection",
    654: "Voice Call Started",
    655: "Voice Call Stopped",
    658: "Voice Call Failed",
    660: "Voice Call Summary",
    638: "Voice Consolidated Report Warning",
    669: "Voice Consolidated Report Info"
})

export const ALL_WIFI_EVENT_LABELS_TO_CODE = Object.freeze({
    "Connection Started": 664,
    "Connection Completed": 610,
    "Connection Failed": 611,
    "Disconnection": 632,
    "Disconnection": 641,
    "Roam Started": 612,
    "Roam Completed": 614,
    "Roam Failed": 615,
    "Voice Call Started": 654,
    "Voice Call Stopped": 655,
    "Voice Call Failed": 658,
    "Voice Call Summary": 660,
    "Voice Consolidated Report Warning": 638,
    "Voice Consolidated Report Info": 669
})

export const ALL_WIFI_EVENT_COLORS = Object.freeze({
    664: "#577707",
    610: "#00E396",
    611: "#0ea0d8",
    612: "#70a096",
    614: "#775DD0",
    615: "#FEB019",
    632: "#FF4560",
    641: "#FF4560",
    654: "#2a2ed7",
    655: "#0ed7b5",
    658: "#FF0ed7",
    660: "#7F0ed7",
    638: "#5F1001",
    669: "#20Fe07"
})
export const ALL_WIFI_PERFORMANCE_LABELS_COLORS = Object.freeze({
    "RSSI": "#febc2e",
    "Packet Loss": "#f55b53",
    "MOS Score": "#27c93f",
})
export const ALL_WIFI_PERFORMANCE_LABELS_MCOLORS = Object.freeze({
    "RSSI": "#febc2e77",
    "Packet Loss": "#f55b5377",
    "MOS Score": "#27c93f77",
})
export const ROAM_WIFI_EVENT_LABELS = Object.freeze({
    664: "Connection Started",
    610: "Connection Completed",
    611: "Connection Failed",
    612: "Roam Started",
    614: "Roam Completed",
    615: "Roam Failed",
    632: "Disconnection",
    641: "Disconnection",
})

export const ROAM_WIFI_EVENT_LABELS_TO_CODE = Object.freeze({
    "Connection Started": 664,
    "Connection Completed": 610,
    "Connection Failed": 611,
    "Roam Started": 612,
    "Roam Completed": 614,
    "Roam Failed": 615,
    "Disconnection": 632,
    "Disconnection": 641,

})

export const ROAM_WIFI_EVENT_COLORS = Object.freeze({
    664: "#577707",
    610: "#00E396",
    611: "#0ea0d8",
    612: "#70a096",
    614: "#775DD0",
    615: "#FEB019",
    632: "#FF4560",
    641: "#FF4560",
})
export const ROAM_WIFI_PERFORMANCE_LABELS_COLORS = Object.freeze({
    "RSSI": "#febc2e"
})

export const CELLULAR_ALL_PERFORMANCE_COLORS = Object.freeze([
    {
        "Handover": {
            "name": "SIM Card 1 Handover",
            "color": "#AC325C",
        },
        "Sim": {
            "name": "SIM Card 1",
            "color": "#8F4E05"
        }
    },
    {
        "Handover": {
            "name": "SIM Card 2 Handover",
            "color": "#001D5B",
        },
        "Sim": {
            "name": "SIM Card 2",
            "color": "#018786"
        }
    },
    {
        "Handover": {
            "name": "SIM Card 3 Handover",
            "color": "#001D5B",
        },
        "Sim": {
            "name": "SIM Card 3",
            "color": "#018786"
        }
    },
    {
        "Handover": {
            "name": "SIM Card 4 Handover",
            "color": "#001D5B",
        },
        "Sim": {
            "name": "SIM Card 4",
            "color": "#018786"
        }
    },
    {
        "Handover": {
            "name": "SIM Card 5 Handover",
            "color": "#001D5B",
        },
        "Sim": {
            "name": "SIM Card 5",
            "color": "#018786"
        }
    },
])

export const CELLULAR_ALL_HANDOVER_TO_CODE_MAP = Object.freeze({
    "IWLAN": 20,
    "NR_SA": 50,
    "LTE": 80,
    "3G": 110
})

export const CELLULAR_ALL_CODE_TO_HANDOVER_MAP = Object.freeze({
    20: "IWLAN",
    50: "NR_SA",
    80: "LTE",
    110: "3G"
})

export const CELLULAR_VOICE_CODE_TO_HANDOVER_MAP = Object.freeze({
    10: "IWLAN",
    40: "NR_SA",
    70: "LTE",
    100: "3G"
})

export const CELLULAR_VOICE_HANDOVER_TO_CODE_MAP = Object.freeze({
    "IWLAN": 10,
    "NR_SA": 40,
    "LTE": 70,
    "3G": 100
})

export const CELLULAR_THROUGHPUT_CODE_TO_HANDOVER_MAP = Object.freeze({
    10: "IWLAN",
    40: "NR_SA",
    70: "LTE",
    100: "3G"
})

export const CELLULAR_THROUGHPUT_HANDOVER_TO_CODE_MAP = Object.freeze({
    "IWLAN": 10,
    "NR_SA": 40,
    "LTE": 70,
    "3G": 100
})

export const CELLULAR_IP_CODE_TO_HANDOVER_MAP = Object.freeze({
    10: "IWLAN",
    40: "NR_SA",
    70: "LTE",
    100: "3G"
})

export const CELLULAR_IP_HANDOVER_TO_CODE_MAP = Object.freeze({
    "IWLAN": 10,
    "NR_SA": 40,
    "LTE": 70,
    "3G": 100
})

export const CELLULAR_ALL_ANALYSIS_FAILURE_EVENTS_CODE = Object.freeze({
    "Acquisition": 7,
    "Data Disconnection": 10,
    "Handover": 19,
    "Out of Service": 4,
    "Voice Call": 14
})

export const CELLULAR_ALL_ANALYSIS_EVENTS_CODE = Object.freeze({
    "Subscription Info": 1,
    "Signal Quality": 3,
    "Signal Info": 3,
    "Out of Service Failures": 4,
    "Service State": 4,
    "Acquisition Failures": 7,
    "IP Packet Error Rate": 9,
    "Data Disconnection": 10,
    "Latency": 11,
    "IMS Status": 12,
    "Voice Call Info": 13,
    "Call Info": 13,
    "Voice Call Failures": 14,
    "CS Call": 14,
    "PS Call": 14,
    "Device Info": 15,
    "Battery": 16,
    "Location": 17,
    "Handover Stats": 18,
    "HO Stats": 18,
    "Handover Success": 19,
    "Handover Failure": 19,
    "Handover": 19,
    "Poor Data Throughput": 20,
    "Data Throughput": 20,
    "BLER": 20,
    "Voice Handover Info": 21,
    "Cell Interference": 22,
    "Poor Coverage": 23
})

// need to implement  partial matching for Events code

// export const CELLULAR_ALL_TIMELINE_EVENT_LABELS = Object.freeze({
//     "Acquisition Failures": "Acquisition Failure",
//     "Data Disconnection": "Data Disconnection Failure",
//     "Handover Failure": "Handover Failure",
//     // "Handover Success": "Handover Success",
//     "Out of Service Failures": "Out of Service Failure",
//     "Voice Call Failures": "Voice Call Failure"
// })

export const CELLULAR_ALL_TIMELINE_EVENT_LABELS = Object.freeze({
    "Acquisition Failure": "Acquisition Failure",
    "Data Disconnection": "Data Disconnection Failure",
    "Handover Failure": "Handover Failure",
    // "Handover Success": "Handover Success",
    "Out of Service Failure": "Out of Service Failure",
    "Voice Call Failure": "Voice Call Failure"
})

// export const CELLULAR_ALL_TIMELINE_EVENT_COLORS = Object.freeze({
//     "Acquisition Failures": "#1578D4",
//     "Data Disconnection": "#E377C2",
//     "Handover Failure": "#43008F",
//     // "Handover Success": "#00864F",
//     "Out of Service Failures": "#24BECE",
//     "Voice Call Failures": "#FF7F0E"
// })
export const CELLULAR_ALL_TIMELINE_EVENT_COLORS = Object.freeze({
    "Acquisition Failure": "#1578D4",
    "Data Disconnection": "#E377C2",
    "Handover Failure": "#43008F",
    // "Handover Success": "#00864F",
    "Out of Service Failure": "#24BECE",
    "Voice Call Failure": "#FF7F0E"
})

// VOICE
export const CELLULAR_DEVICE_VOICE_PERFORMANCE_COLORS = Object.freeze([
    {
        "Handover": {
            "name": "SIM Card 1 Handover",
            "color": "#8F2F69"
        },

    },
    {
        "Handover": {
            "name": "SIM Card 2 Handover",
            "color": "#001D5B"
        },

    },
    {
        "Handover": {
            "name": "SIM Card 3 Handover",
            "color": "#00100B"
        },

    },
])

export const CELLULAR_DEVICE_VOICE_FAILURE_EVENT_COLORS = Object.freeze({
    "CS Call": "#1578D4",
    "PS Call": "#945BC7",
})

export const CELLULAR_DEVICE_VOICE_TIMELINE_EVENT_LABELS = Object.freeze({
    "CS Call": "CS Call",
    "PS Call": "PS Call",
})

export const CELLULAR_DEVICE_VOICE_TIMELINE_EVENT_COLORS = Object.freeze({
    "CS Call": "#1578D4",
    "PS Call": "#945BC7",
})
//IP PERFORMANCE
export const CELLULAR_DEVICE_IP_FAILURE_EVENT_COLORS = Object.freeze({
    "Acquisition": "#1578D4",
    "Data Disconnection": "#E377C2",
    "Handover": "#43008F",
})

export const CELLULAR_DEVICE_IP_TIMELINE_EVENT_LABELS = Object.freeze({
    "Acquisition Failure": "Acquisition",
    "Data Disconnection": "Data Disconnection",
    "Handover Failure": "Handover",
})

export const CELLULAR_DEVICE_IP_TIMELINE_EVENT_COLORS = Object.freeze({
    "Acquisition Failure": "#1578D4",
    "Data Disconnection": "#E377C2",
    "Handover Failure": "#43008F",
})

export const CELLULAR_DEVICE_IP_PERFORMANCE_COLORS = Object.freeze([
    {
        "Handover": {
            "name": "SIM Card 1 Handover",
            "color": "#AC325C"
        },
        "RX": {
            "name": "SIM Card 1 RX",
            "color": "#769C00"
        },
        "TX": {
            "name": "SIM Card 1 TX",
            "color": "#9102FF"

        }
    },
    {
        "Handover": {
            "name": "SIM Card 2 Handover",
            "color": "#001D5B"
        },
        "RX": {
            "name": "SIM Card 2 RX",
            "color": "#018786"
        },
        "TX": {
            "name": "SIM Card 2 TX",
            "color": "#91F2FF"
        }
    },
    {
        "Handover": {
            "name": "SIM Card 3 Handover",
            "color": "#00100B"
        },
        "RX": {
            "name": "SIM Card 3 RX",
            "color": "#018006"
        },
        "TX": {
            "name": "SIM Card 3 TX",
            "color": "#91F20F"
        }
    },
])

// THROUGHPUT
export const CELLULAR_DEVICE_THROUGHPUT_FAILURE_EVENT_COLORS = Object.freeze({
    "Cell Interference": "#855E41",
    "Poor Coverage": "#311DAA"
})

export const CELLULAR_DEVICE_THROUGHPUT_TIMELINE_EVENT_LABELS = Object.freeze({
    "Cell Interference": "Cell Interference",
    "Poor Coverage": "Poor Coverage"
})

export const CELLULAR_DEVICE_THROUGHPUT_TIMELINE_EVENT_COLORS = Object.freeze({
    "Cell Interference": "#855E41",
    "Poor Coverage": "#311DAA"
})

export const CELLULAR_DEVICE_THROUGHPUT_PERFORMANCE_COLORS = Object.freeze([
    {
        "Handover": {
            "name": "SIM Card 1 Handover",
            "color": "#AC325C"
        },
        "RX": {
            "name": "SIM Card 1 RX",
            "color": "#769C00"
        },
        "TX": {
            "name": "SIM Card 1 TX",
            "color": "#9102FF"

        }
    },
    {
        "Handover": {
            "name": "SIM Card 2 Handover",
            "color": "#001D5B"
        },
        "RX": {
            "name": "SIM Card 2 RX",
            "color": "#018786"
        },
        "TX": {
            "name": "SIM Card 2 TX",
            "color": "#91F2FF"
        }
    },
    {
        "Handover": {
            "name": "SIM Card 3 Handover",
            "color": "#00100B"
        },
        "RX": {
            "name": "SIM Card 3 RX",
            "color": "#018006"
        },
        "TX": {
            "name": "SIM Card 3 TX",
            "color": "#91F20F"
        }
    },
])


// Table names
export const MAIN_DASH_TABLE = "wirelessInsight"
export const GROUP_DASH_WIFI_TABLE = "wifiInsight"
export const DEVICE_DASH_ALL_TABLE = "wifiDeviceAllAnalysis"
export const DEVICE_DASH_ROAM_TABLE = "wifiDeviceRoamAnalysis"
export const GROUP_DASH_CELLULAR_TABLE = "cellularInsights"
export const DEVICE_DASH_CELLULAR_ALL_TABLE = "cellularDeviceAllAnalysis"
export const DEVICE_DASH_CELLULAR_VOICE_TABLE = "cellularDeviceVoiceAnalysis"
export const DEVICE_DASH_CELLULAR_IP_TABLE = "cellularDeviceIPAnalysis"
export const DEVICE_DASH_CELLULAR_THROUGHPUT_TABLE = "cellularDeviceThroughputAnalysis"
export const SERIES_NULL_CSS = Object.freeze([
    {
        name: " ",
        color: "#0061C200",
        data: [null]
    },
    {
        name: " ",
        color: "#D7001500",
        data: [null]
    }
])
export const SERIES_NULL_SSF = Object.freeze([
    {
        name: "Signal Strength",
        color: "#0061C2",
        data: [null]
    },
    {
        name: "Failures",
        color: "#D70015",
        data: [null]
    }
])
export const DEFAULT_WIFI_EVENT_DATA = Object.freeze({
    "Wi-Fi Disconnection": 0,
    "Wi-Fi Voice": 0,
    "Wi-Fi Roam": 0,
    "Wi-Fi Connection": 0
})
export const DEFAULT_CELLULAR_EVENT_DATA = Object.freeze({
    "Out of Service Failures": 0,
    "Acquisition Failures": 0,
    "Voice Call Failures": 0,
})
export const DEFAULT_QUALITY_DATA = Object.freeze({
    "Excellent": 0,
    "Very Good": 0,
    "Good": 0,
    "Fair": 0,
    "Poor": 0
})

export const DEFAULT_QUALITY_GROUPS = Object.freeze({
    "Excellent": [],
    "Very Good": [],
    "Good": [],
    "Fair": [],
    "Poor": []
})

export const WIFI_DASHBOARD = "WIFI_DASHBOARD"
export const CELLULAR_DASHBOARD = "CELLULAR_DASHBOARD"
//Main dashboard 
export const WIFI_EVENTS_M = "WIFI_EVENT"
export const WIFI_CONNECTION_QUALITY_M = "CONNECTION_QUALITY"
export const WIFI_SIGNAL_STRENGTH_FAILURE_M = "WIFI_SIGNAL_STRENGTH_FAILURE"
export const CELLULAR_EVENTS_M = "CELLULAR_EVENT"
export const CELLULAR_SIGNAL_STRENGTH_FAILURE_M = "CELLULAR_SIGNAL_STRENGTH_FAILURE"
export const CELLULAR_CONNECTION_QUALITY_M = "CELLULAR_CONNECTION_QUALITY"
//Group level dashboard - Cellular
export const CELLULAR_CONNECTION_QUALITY = "CELLULAR_CONNECTION_QUALITY"
export const CELLULAR_SIGNAL_STRENGTH_CARRIER = "CELLULAR_SIGNAL_STRENGTH_CARRIER"
export const CELLULAR_SIGNAL_STRENGTH_DEVICE = "CELLULAR_SIGNAL_STRENGTH_DEVICE"
export const CELLULAR_EVENTS = "CELLULAR_EVENTS"
export const TABLE_DATA = "TABLE_DATA"
export const TABLE_SORT = "sort"
export const TABLE_FILTER = "filter"
export const TABLE_PAGINATE = "paginate"

export const CHART_WIFI_QUALITY = "WifiQuality"
export const CHART_WIFI_EVENT = "WifiEvent"
export const CHART_CELLULAR_EVENT = "CellularEvent"
export const CHART_CELLULAR_SS = "CellularSignalStrength"
export const CHART_WIFI_TOTAL_EVENTS = "total-events-group-dashboard"
export const WIFI_TABLE = "DEVICE_TABLE"

export const CHART_WIFI_DEVICE_FAILURE_CATEGORY = "FAILURE_CATEGORY"
export const CHART_WIFI_DEVICE_VOICE_CALL_SUMMARY = "VOICE_CALL_SUMMARY"
export const CHART_WIFI_DEVICE_ROAM_STATISTICS = "ROAM_STATISTICS"
export const CHART_WIFI_DEVICE_EVENT_COUNT = "EVENT_COUNT"
export const CHART_WIFI_DEVICE_SIGNAL_STRENGTH_FAILURE = "SIGNAL_STRENGTH_FAILURE"
export const CHART_WIFI_DEVICE_DEVICE_TABLE = "DEVICE_TABLE"
export const CHART_WIFI_DEVICE_EVENT_TIMELINE = "EVENTS_TIMELINE"
export const CHART_WIFI_DEVICE_EVENT_DETAILS = "EVENT_DATA"

export const CHART_WIFI_DEVICE_ROAM_FAILURE_CATEGORY = "FAILURE_CATEGORY"
export const CHART_WIFI_DEVICE_ROAM_VOICE_CALL_SUMMARY = "VOICE_CALL_SUMMARY"
export const CHART_WIFI_DEVICE_ROAM_ROAM_STATISTICS = "ROAM_STATISTICS"
export const CHART_WIFI_DEVICE_ROAM_EVENT_COUNT = "EVENT_COUNT"
export const CHART_WIFI_DEVICE_ROAM_SIGNAL_STRENGTH_FAILURE = "SIGNAL_STRENGTH_FAILURE"
export const CHART_WIFI_DEVICE_ROAM_DEVICE_TABLE = "DEVICE_TABLE"
export const CHART_WIFI_DEVICE_ROAM_EVENT_TIMELINE = "EVENTS_TIMELINE"
export const CHART_WIFI_DEVICE_ROAM_EVENT_DETAILS = "EVENT_DATA"

export const CHART_CELLULAR_DEVICE_FAILURE_EVENTS = "EVENTS_ANALYSIS"
export const CHART_CELLULAR_DEVICE_TOTAL_HANDOVER_EVENTS = "HANDOVER_EVENTS"
export const CHART_CELLULAR_DEVICE_EVENT_TABLE = "EVENTS_TABLE"
export const CHART_CELLULAR_DEVICE_EVENT_TIMELINE = "EVENTS_TIMELINE"
export const CHART_CELLULAR_DEVICE_EVENT_DETAILS = "EVENT_DATA"

export const CHART_CELLULAR_DEVICE_VOICE_FAILURE_EVENTS = "EVENTS_ANALYSIS"
export const CHART_CELLULAR_DEVICE_VOICE_TOTAL_HANDOVER_EVENTS = "HANDOVER_EVENTS"
export const CHART_CELLULAR_DEVICE_VOICE_EVENT_TABLE = "EVENTS_TABLE"
export const CHART_CELLULAR_DEVICE_VOICE_EVENT_TIMELINE = "EVENTS_TIMELINE"
export const CHART_CELLULAR_DEVICE_VOICE_EVENT_DETAILS = "EVENT_DATA"

export const CHART_CELLULAR_DEVICE_IP_FAILURE_EVENTS = "EVENTS_ANALYSIS"
export const CHART_CELLULAR_DEVICE_IP_TOTAL_HANDOVER_EVENTS = "HANDOVER_EVENTS"
export const CHART_CELLULAR_DEVICE_IP_EVENT_TABLE = "EVENTS_TABLE"
export const CHART_CELLULAR_DEVICE_IP_EVENT_TIMELINE = "EVENTS_TIMELINE"
export const CHART_CELLULAR_DEVICE_IP_EVENT_DETAILS = "EVENT_DATA"

export const CHART_CELLULAR_DEVICE_THROUGHPUT_FAILURE_EVENTS = "EVENTS_ANALYSIS"
export const CHART_CELLULAR_DEVICE_THROUGHPUT_TOTAL_HANDOVER_EVENTS = "HANDOVER_EVENTS"
export const CHART_CELLULAR_DEVICE_THROUGHPUT_EVENT_TABLE = "EVENTS_TABLE"
export const CHART_CELLULAR_DEVICE_THROUGHPUT_EVENT_TIMELINE = "EVENTS_TIMELINE"
export const CHART_CELLULAR_DEVICE_THROUGHPUT_EVENT_DETAILS = "EVENT_DATA"

export const CHART_WIFI_DEVICE_ROAM = "ROAM"
export const CHART_CELLULAR_DEVICE_VOICE = "VOICE_CALL"
export const CHART_CELLULAR_DEVICE_IP = "IP_PERFORMANCE"
export const CHART_CELLULAR_DEVICE_THROUGHPUT = "THROUGHPUT"





export const TAB_WIFI_INSIGHTS = "Wi-Fi Insights"
export const TAB_CELLULAR_INSIGHTS = "Cellular Insights"

export const WIFI_ALL_ANNOTATED_CHART_OPTIONS = Object.freeze({
    "y1title": "Packet Loss %",
    "y2title": "RSSI (dBm)",
    "y1Min": 0,
    "y1Max": 110,
    "y2Min": 0,
    "y2Max": 110,
    "y1tickAmount": 11,
    "y2tickAmount": 11,
    "markerSizes": [4, 4, 4, 8, 0],
    "markerColors": [...Object.values(ALL_WIFI_PERFORMANCE_LABELS_MCOLORS), "#FFFF00", "#FFFFFF00"],
    "y1labels": ["0%", "10%", "20%", "30%", "40%", "50%", "60%", "70%", "80%", "90%", "100%"],
    "y2labels": ["0", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100"]
})

export const WIFI_ROAM_ANNOTATED_CHART_OPTIONS = Object.freeze({
    "y1title": "RSSI (dBm)",
    "y2title": "",
    "y1Min": 0,
    "y1Max": 110,
    "y2Min": 0,
    "y2Max": 110,
    "y1tickAmount": 11,
    "y2tickAmount": 11,
    "markerSizes": [4, 6, 0],
    "markerColors": [...Object.values(ROAM_WIFI_PERFORMANCE_LABELS_COLORS), "#FFFF00", "#FFFFFF00"],
    "y1labels": ["0%", "10%", "20%", "30%", "40%", "50%", "60%", "70%", "80%", "90%", "100%"],
    "y2labels": ["0", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100"],
})

export const CELLULAR_ALL_ANNOTATED_CHART_OPTIONS = Object.freeze({
    "y1title": "Handover",
    "y2title": "Signal Strength",
    "y1Min": 10,
    "y1Max": 130,
    "y2Min": 10,
    "y2Max": 130,
    "y1tickAmount": 12,
    "y2tickAmount": 12,
    "markerSizes": [],
    "markerColors": [],
    "y1labels": ["", "", "IWLAN", "", "", "NR_SA", "", "", "LTE", "", "", "3G", "", "", ""],
    "y2labels": ["", "", "-20", "-30", "-40", "-50", "-60", "-70", "-80", "-90", "-100", "-110", "-120", "", ""],
    "handoverMap": CELLULAR_ALL_CODE_TO_HANDOVER_MAP,
    "tooltipVal": -1
})

export const DEFAULT_TIMELINE = Object.freeze({
    "performance": [
        {
            type: 'scatter',
            name: 'Events',
            data: []
        }], "events": [], "event_ids": []
})

export const DEFAULT_TIMELINE_WIFI = Object.freeze({
    "performance": [], "events": [], "event_ids": []
})

export const CELLULAR_THROUGHPUT_ANNOTATED_CHART_OPTIONS = Object.freeze({
    "y1title": "Handover",
    "y2title": "BLER",
    "y1Min": 0,
    "y1Max": 120,
    "y2Min": 0,
    "y2Max": 120,
    "y1tickAmount": 12,
    "y2tickAmount": 12,
    "markerSizes": [],
    "markerColors": [],
    "y1labels": ["", "IWLAN", "", "", "NR_SA", "", "", "LTE", "", "", "3G", "", ""],
    "y2labels": ["", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100", "", ""],
    "handoverMap": CELLULAR_THROUGHPUT_CODE_TO_HANDOVER_MAP,
    "tooltipVal": 1
})

export const CELLULAR_IP_ANNOTATED_CHART_OPTIONS = Object.freeze({
    "y1title": "Handover",
    "y2title": "IP Packet Loss %",
    "y1Min": 0,
    "y1Max": 120,
    "y2Min": 0,
    "y2Max": 120,
    "y1tickAmount": 12,
    "y2tickAmount": 12,
    "markerSizes": [],
    "markerColors": [],
    "y1labels": ["", "IWLAN", "", "", "NR_SA", "", "", "LTE", "", "", "3G", "", ""],
    "y2labels": ["", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100", "", ""],
    "handoverMap": CELLULAR_IP_CODE_TO_HANDOVER_MAP,
    "tooltipVal": 1
})

export const CELLULAR_VOICE_ANNOTATED_CHART_OPTIONS = Object.freeze({
    "y1title": "Handover",
    "y2title": "",
    "y1Min": 0,
    "y1Max": 120,
    "y2Min": 0,
    "y2Max": 120,
    "y1tickAmount": 12,
    "y2tickAmount": 12,
    "markerSizes": [],
    "markerColors": [],
    "y1labels": ["", "IWLAN", "", "", "NR_SA", "", "", "LTE", "", "", "3G", "", ""],
    "y2labels": [],
    "handoverMap": CELLULAR_IP_CODE_TO_HANDOVER_MAP,
    "tooltipVal": 1
})

export const WIFI_SIGNAL_SF_CHART_OPTIONS = Object.freeze({
    'yMin': 0,
    'yMax': 6,
    'xtickAmount': 6,
    'y1tickAmount': 6,
    'y2tickAmount': 6,
    'y1TickLabels': WIFI_SIGNAL_STRENGTH_FAILURE_Y1TICK_LABELS,
    'y2TickLabels': WIFI_SIGNAL_STRENGTH_FAILURE_Y2TICK_LABELS,
    'markerColors': Object.values(WIFI_SIGNAL_STRENGTH_FAILURE_COLORS)
})

export const WIFI_SIGNAL_GROUP_SF_CHART_OPTIONS = Object.freeze({
    "yMin": 0,
    "yMax": 6,
    "xtickAmount": 4,
    "y1TickLabels": WIFI_SIGNAL_STRENGTH_FAILURE_Y1TICK_LABELS,
    "y2TickLabels": WIFI_SIGNAL_STRENGTH_FAILURE_Y2TICK_LABELS,
    "y1tickAmount": 6,
    "y2tickAmount": 6,
    "markerColors": Object.values(WIFI_SIGNAL_STRENGTH_FAILURE_COLORS)
})

export const CELLULAR_SF_CHART_OPTIONS = Object.freeze({
    'yMin': 0,
    'yMax': 6,
    'xtickAmount': 4,
    'y1tickAmount': 6,
    'y2tickAmount': 6,
    "y1TickValues": WIFI_SIGNAL_STRENGTH_FAILURE_Y1TICK_LABELS,
    "y2TickValues": WIFI_SIGNAL_STRENGTH_FAILURE_Y2TICK_LABELS,
    "markerColors": CELLULAR_SS_COLORS
})

export const CELLULAR_GROUP_SF_CHART_OPTIONS = Object.freeze({
    'yMin': 0,
    'yMax': 6,
    'xtickAmount': 4,
    'y1tickAmount': 6,
    'y2tickAmount': 6,
    "y1TickValues": WIFI_SIGNAL_STRENGTH_FAILURE_Y1TICK_LABELS,
    "y2TickValues": WIFI_SIGNAL_STRENGTH_FAILURE_Y2TICK_LABELS,
    "markerColors": CELLULAR_SS_COLORS
})


export const NEIGHBOR_CELL_SS = "neighborCellSS"
export const SERVING_CELL_SS = "servingCellSS"

// Event filters
export const CELLULAR_DEVICE_ALL_FILTER_EVENT_HANDOVER = "Handover Success"
export const CELLULAR_DEVICE_ALL_FILTER_EVENT_STRENGTH = "Signal Info"

export const CELLULAR_DEVICE_IP_FILTER_EVENT_HANDOVER = "Handover Success"
export const CELLULAR_DEVICE_IP_FILTER_EVENT_RX_TX = "IP Packet Error Rate"

export const CELLULAR_DEVICE_THROUGHPUT_FILTER_EVENT_HANDOVER = "Handover Success"
export const CELLULAR_DEVICE_THROUGHPUT_FILTER_EVENT_BLER = "BLER"

export const CELLULAR_DEVICE_VOICE_FILTER_EVENT_HANDOVER = "Handover Success"


export const WIFI_ROAM_KPI = Object.freeze({
    "CONNECTION_FAILURES": "Connection Failures",
    "DISCONNECTION_FAILURES": "Disconnection Failures",
    "ROAM_FAILURES": "Roam Failures"
})

export const WIFI_VOICE_KPI = Object.freeze({
    "TOTAL_VOIP_CALLS": "Total VoIP Calls",
    "VOIP_CALL_DURATION": "VoIP Call Duration",
    "VOIP_AVERAGE_MOS": "VoIP Average MOS"
})

export const WIFI_SPECIAL_EVENTS = Object.freeze({ "Wlan_Code": "Wlan_Code_Category", "Reason_Code": "Reason" })
export const WIFI_IGNORE_EVENTS = Object.freeze(["Wlan_Code_Category", "Reason", "Timestamp"])
export const WIFI_COLOR_EVENT = "Wlan_Code"
export const WIFI_TITLE_EVENT = "Wlan_Code_Category"
export const WIFI_TIME_EVENT = "Timestamp"

export const CELLULAR_SPECIAL_EVENTS = Object.freeze(["signalStrength"])
export const CELLULAR_IGNORE_EVENT = "currentEventMetaData"
export const CELLULAR_COLOR_EVENT = "event_name"
export const CELLULAR_TITLE_EVENT = "event_name"
export const CELLULAR_TIME_EVENT = "utc_timestamp"
export const CELLULAR_VOICE_ERROR_TYPE = "error_type"

export const SPLIT_D = "and"
export const WI_CLIENT_VERSION = process.env.REACT_APP_WI_CLIENT_VERSION || "1.0.0.0"
export const WIRELESS_INSIGHTS_VERSION = process.env.REACT_APP_WIRELESS_INSIGHTS_VERSION || "1.0.0.0"
export const WI_REGION = process.env.REGION || "us-central1"

export const DEFAULT_DEVICE = Object.freeze([
    {
        'key': '0',
        'id': null,
        'deviceModel': ' ',
        'deviceName': " ",
        'osVersion': " ",
        'securityPatchDate': { "value": " " },
        'serial': " ",
        'imei': " ",
    }
])

export const DEFAULT_GROUP = Object.freeze([
    {
        'key': '0',
        'id': null,
        'group_name': " ",
        'deviceCount': " ",
    }
])

export const DEFAULT_USER = Object.freeze([
    {
        'key': '0',
        'id': null,
        'userName': ' ',
        'userEmail': " ",
        'userRole': ' ',

    }
])

export const DAY_INDEX = Object.freeze(
    {
        'monday': 1,
        'tuesday': 2,
        'wednesday': 3,
        'thursday': 4,
        'friday': 5,
        'saturday': 6,
        'sunday': 7
    }
)

export const ALERT_VALUE_MAP = Object.freeze({
    "Wi-Fi": "WIFI",
    "Cellular": "CELLULAR",
    "An hour": "HOUR",
    "A day": "DAY",
    "Within an hour": "IMMEDIATE",
    "Daily as scheduled": "DAILY",
    "Weekly as scheduled": "WEEKLY",
    "all_group": "GLOBAL",
    "per_group": "GROUP",
    "per_device": "DEVICE"
})

export const ALERT_CODE_TO_VALUE_MAP = Object.freeze({
    "WIFI": "Wi-Fi",
    "CELLULAR": "Cellular",
    "HOUR": "An hour",
    "DAY": "A day",
    "IMMEDIATE": "Within an hour",
    "DAILY": "Daily as scheduled",
    "WEEKLY": "Weekly as scheduled",
    "GLOBAL": "all_group",
    "GROUP": "per_group",
    "DEVICE": "per_device"
})

export const ERROR_MESSAGE = Object.freeze({
    "USER": "Please select recipient",
    "DEVICE": "Please select device",
    "GROUP": "Please select group"
})

export const CELLULAR_EVENT_OPTIONS = Object.freeze([
    { value: 'Acquisition', label: 'Acquisition' },
    { value: 'Data Disconnection', label: 'Data Disconnection' },
    { value: 'Handover', label: 'Handover' },
    { value: 'Out of Service', label: 'Out of Service' },
    { value: 'Voice Call', label: 'Voice Call' },
])

export const WIFI_EVENT_OPTIONS = Object.freeze([
    { value: 'Connection Started', label: 'Connection Started' },
    { value: 'Connection Completed', label: 'Connection Completed' },
    { value: 'Connection Failed', label: 'Connection Failed' },
    { value: 'Roam Started', label: 'Roam Started' },
    { value: 'Roam Completed', label: 'Roam Completed' },
    { value: 'Roam Failed', label: 'Roam Failed' },
    { value: 'Disconnection', label: 'Disconnection' },
    { value: 'Voice Call Started', label: 'Voice Call Started' },
    { value: 'Voice Call Stopped', label: 'Voice Call Stopped' },
    { value: ' Voice Call Failed', label: ' Voice Call Failed' },
    { value: 'Voice Call Summary', label: 'Voice Call Summary' },
])

export const WIFI_EVENT_SUGGESTION_ENUM = Object.freeze({
    "Disconnection": 'DISCONNECT',
    "Voice": 'VOICE',
    "Roam": 'ROAM',
    "Connection": 'CONNECTION'
});

export const WIFI_EVENT_SUGGESTION_TO_CODE_ENUM = Object.freeze({
    "disconnection": 'DISCONNECT',
    "voice": 'VOICE',
    "roam": 'ROAM',
    "connection": 'CONNECTION'
});

export const CELLULAR_EVENT_SUGGESTION_ENUM = Object.freeze({
    "Acquisition": "ACQUISITION",
    "Handover": "HANDOVER",
    "Out of Service": "OUT_OF_SERVICE",
    "Voice Call": "VOICE_CALL",
})

export const CELLULAR_EVENT_SUGGESTION_TO_CODE_ENUM = Object.freeze({
    "acquisition": "ACQUISITION",
    "aandover": "HANDOVER",
    "out of service": "OUT_OF_SERVICE",
    "voice call": "VOICE_CALL",
})

export const SEARCH_KEY_WARDS =Object.freeze(["group", "name", "model", "serial number", "network type", "event name", "os version", "security patch date"])