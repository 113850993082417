// NEW CI&T
export default {
  apiKey: "AIzaSyBvNSHqopKIhph7yAer8GmBuN3jwwbmGcs",
  authDomain: "zdnacloud-conftool-d-d.firebaseapp.com",
  projectId: "zdnacloud-conftool-d-d",
  storageBucket: "zdnacloud-conftool-d-d.appspot.com",
  messagingSenderId: "97569770125",
  appId: "1:97569770125:web:f32cc4e718dae110b3f0c4"
};


// NEW CI&T
// export default {
//   apiKey: "AIzaSyBH23abGEMQm1FTuH2W2LT-4WrinyfWeJY",
//   authDomain: "zdnacloud-prodenv-t.firebaseapp.com",
//   projectId: "zdnacloud-prodenv-t",
//   storageBucket: "zdnacloud-prodenv-t.appspot.com",
//   messagingSenderId: "649958032132",
//   appId: "1:649958032132:web:11aeba08e9c9175804c651"
// }