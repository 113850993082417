import { initializeApp } from 'firebase/app'
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  GithubAuthProvider,
  onAuthStateChanged,
} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import firebaseConfig from 'configs/FirebaseConfig'
import { FIREBASE_USERNAME, TENANT_CREATED_TIME, TENANT_ID } from 'redux/constants/Auth'
import { getFunctions, httpsCallable } from 'firebase/functions'

const firebase = initializeApp(firebaseConfig)

const getRegionalFunctions = () => {
  return getFunctions(firebase, process.env.REACT_APP_FIREBASE_FUNCTIONS_REGION);
};

// firebase utils
const db = getFirestore(firebase)
const auth = getAuth(firebase)
const currentUser = auth.currentUser
const googleAuthProvider = new GoogleAuthProvider()
const facebookAuthProvider = new FacebookAuthProvider()
const twitterAuthProvider = new TwitterAuthProvider()
const githubAuthProvider = new GithubAuthProvider()

const getTenantId = (user) => {
  //console.log('getTenantId ' + user)
  if (user && user.claims && user.claims.user_id) return user.claims.user_id
  else return 'unknown'
  /* if (localStorage.getItem(TENANT_ID) === null) {
    return 'unknown'
  } else {
    return localStorage.getItem(TENANT_ID)
  } */
}

const getUserId = (user) => {
  if (user && user.claims && user.claims.sub) return user.claims.sub
  else return 'unknown'
}

const getFriendlyName = (user) => {
  if (user && user.claims && user.claims.friendlyId) return user.claims.friendlyId
  else return ''
}

const getUserPermission = (user) => {
  if (user && user.claims && user.claims.userPermissions) return user.claims.userPermissions
  else return ''
}

const getEmailId = (user) => {
  return user && user.email ? user.email : user?.claims?.email
}

const getWiPermission = (user) => { // Wireless Insights code
  return user?.claims?.wiPermission
}

const getRole = (user) => {
  return user?.claims?.role
}

const getCustomRole = (user) => {
  return user?.claims?.customRoleName
}

const getLoginType = (user) => {
  console.log('login type: ', user?.claims?.loginType)
  return user?.claims?.loginType ? user?.claims?.loginType : '1'
}

const getFirebaseUserName = (user) => {
  if (user?.claims[FIREBASE_USERNAME]) {
    return user.claims[FIREBASE_USERNAME]
  } else {
    let mailId = user && user.email ? user?.email : user?.claims?.email
    return mailId && mailId != 'undefined' ? mailId.split('@')[0].toUpperCase() : 'unknown'
  }
}

const hasLgeServiceAccess = (user) => {
  let hasLgeAccess = false
  if (user?.claims['enrollmentTokenAvailable']) {
    return user.claims['enrollmentTokenAvailable']
  } else {
    return hasLgeAccess
  }
}
const getTenantCreationTime = (user) => {
  /* if(localStorage.getItem(TENANT_CREATED_TIME)) {
    return parseInt(localStorage.getItem(TENANT_CREATED_TIME))
  } */
  //console.log('getTenantCreationTime ' + JSON.stringify(user.claims))
  if (user?.claims?.[TENANT_CREATED_TIME]) {
    return user.claims[TENANT_CREATED_TIME]
  } else {
    return ''
  }
}

export {
  db,
  auth,
  firebase,
  currentUser,
  googleAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  githubAuthProvider,
  getTenantId,
  getEmailId,
  getFirebaseUserName,
  getTenantCreationTime,
  getRole,
  hasLgeServiceAccess,
  getUserId,
  getLoginType,
  onAuthStateChanged,
  getFriendlyName,
  getUserPermission,
  getCustomRole,
  getWiPermission,
  getRegionalFunctions,
}
